// require('dotenv').config();
export const apiConfig = {
  // host: process.env.REACT_APP_HOST,
  host: 'https://fitmealworld.com/api/cms',
  webHost: 'https://fitmealworld.com/api/web',
  // host: 'http://localhost:8888',
  // apiVersion: process.env.REACT_APP_APIVERSION,
  apiVersion: '/api/v1',
  // client_id: process.env.REACT_APP_CLIENT_ID,
  client_id: 2,
  // client_secret: process.env.REACT_APP_CLIENT_SECRET,
  client_secret: 'cLEqGwcqqObRstHIIUV2VqcyYPUkc0ZNfvauh92u',
  // aToken: process.env.REACT_APP_ATOKEN,
  aToken: 'oE4G6phEvq1L63RVFk5+poFxDBVhATJUPhoxD7N7o6M',
};


export const hostUrl = `${apiConfig.host}`;
export const webHostUrl = `${apiConfig.webHost}`;

export const sliderConfig = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  centerMode: true,
  centerPadding: '90px',
  responsive: [
    {
      breakpoint: 1300,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        centerMode: false,
        dots: true
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        dots: true
      }
    }
  ]
};


