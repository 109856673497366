import React from 'react';
import logo from 'assets/img/logoAsset.png';
import { history } from '../../history';

const BottomInfoBlock = () => {
  const goTo = (e, path) => {
    e.preventDefault();
    if (history.location.pathname === path && path === '/gift-card') {
      window.__reset_gift_card__();
    }
    history.push(path);
  }

  return (
    <section className="footer text-white">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <p>
              <img src={logo} width="80" alt={'logo'} />
            </p>
            <p>747-241-7022 <br /> fitmeal.fitlife@gmail.com</p>
          </div>
          <div className="col-md-3">
            <h4>Nationwide Fit Meals</h4>
            <ul>
              <li>
                <a
                  onClick={e => goTo(e, '/about')}
                  href="/about"
                >About Us</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/contact-us')} href="/contact-us">Contact Us</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/news')} href="/news">News</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/privacy-policy')} href="/">Privacy Policy</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/faq')} href="/faq">FAQ</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4>Meal Ordering</h4>

            <ul>
              <li>
                <a onClick={e => goTo(e, '/gift-card')} href="/gift-card">Gift Card</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/how-it-works')} href="/how-it-works">How It Works</a>
              </li>
              <li>
                <a onClick={e => goTo(e, '/weekly-meals')} href="/weekly-meals">Weekly Specials</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3  text-md-right">
            <p>
              <a onClick={e => goTo(e, '/gift-card')} href="/" className="btn btn-white">Buy Gift Card</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BottomInfoBlock;
