export default {
    APP_STATIC__TOGGLE_JOIN_US: 'APP_STATIC__TOGGLE_JOIN_US',
    APP_STATIC__TOGGLE_GET_OFF: 'APP_STATIC__TOGGLE_GET_OFF',
    APP_STATIC__SET_USER: 'APP_STATIC__SET_USER',
    APP_STATIC__SET_OAUTH: 'APP_STATIC__SET_OAUTH',
    APP_STATIC__LOG_OUT: 'APP_STATIC__LOG_OUT',
    APP_STATIC__GENERATE_SESSION_ID: 'APP_STATIC__GENERATE_SESSION_ID',
    APP_STATIC__SET_SESSION_ID: 'APP_STATIC__SET_SESSION_ID',
    APP_STATIC__SET_MANAGING_SUBSCRIPTION: 'APP_STATIC__SET_MANAGING_SUBSCRIPTION',
    APP_STATIC__CLEAR_MANAGING_SUBSCRIPTION: 'APP_STATIC__CLEAR_MANAGING_SUBSCRIPTION',
    TOGGLE_OFFCANVAS_MOBILE_NAV: 'TOGGLE_OFFCANVAS_MOBILE_NAV',
    SETTINGS__ON_LOG_OUT: 'SETTINGS__ON_LOG_OUT',
    SETTINGS__LOG_IN: 'SETTINGS__LOG_IN',
    APP_STATIC__UPDATE_PROFILE: 'APP_STATIC__UPDATE_PROFILE',
    APP_STATIC__GET_ADDRESS: 'APP_STATIC__GET_ADDRESS',
    APP_STATIC__ON_LOG_IN: 'APP_STATIC__ON_LOG_IN',
}
