import React from 'react';
import arrowWhite from 'assets/img/arrow-white.png';

class GetOff extends React.Component {
    toggleModal = e => {
        e.preventDefault();
        this.props.onHide();
    }

    render() {
        return (
            <div className="modal fade" id="newsletter" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body py-0">
                            <div className="meal-plans-box mb-0 row d-md-flex align-items-center flex-wrap">
                                <div className="image-box col-sm-12 col-md-6 p-0">
                                </div>
                                <div className="col-md-6 col-sm-12 p-5">
                                    <h2 className="text-center green mb-2 mt-5"><strong>GET 20% OFF</strong></h2>
                                    <p className="text-center mb-4">Congratulations! You will receive 20% off your
                                        first subscription week.</p>
                                    <p className="text-center small mb-4">*discount will apply only to the first week, once plan renews you will be paying
                                        regular price.</p>
                                    <form className="plan-form mb-5 position-relative" action="#">
                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Email Address"/>
                                        </div>
                                        <button type="submit" className="btn btn-gradient">
                                            <img src={arrowWhite} width="15" alt={'arrowWhite'}/>
                                        </button>
                                    </form>
                                    <p className="text-center mb-3">
                                        <strong>
                                            <a href="/" style={{color: '#000'}} data-dismiss="modal"
                                               onClick={this.toggleModal}>
                                                <u>NO THANKS!</u>
                                            </a>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default GetOff;
