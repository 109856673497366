import axios from 'axios';
import { hostUrl, apiConfig, webHostUrl } from 'config';
import redux from 'reduxStore';

window.axios = axios;

class Axios {
    static get(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                "isfull": "true",
              ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.get(
            `${hostUrl}${url}`,
            config
        );
    }

    static post(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.post(`${hostUrl}${url}`, data, config);
    }

    static postWeb(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.post(`${webHostUrl}${url}`, data, config);
    }

    static put() {

    }

    static delete(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.delete(`${hostUrl}${url}`, config);
    }

    static patch(url, data, options = {}) {
        const oauth = redux.store.getState().appStatic.oauth;
        const config = {
            ...options,
            headers: {
                ...options.headers,
                Accept: "application/vnd.api+json",
                "Account-Token": apiConfig.aToken,
                ...(oauth.access_token ? {Authorization: `${oauth.token_type} ${oauth.access_token}`} : {}),
            }
        };
        return axios.patch(`${hostUrl}${url}`, data, config);
    }

    static fedexApi(url, method,  data, options = {}) {

      return axios({
        url,
        method,
        data,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        proxy: {
          protocol: 'https',
          host: '127.0.0.1',
          // hostname: '127.0.0.1' // Takes precedence over 'host' if both are defined
          port: 9000
        },
      })
    }

    static stripe( url, method,  data, options = {} ) {

        return axios({
            url,
            method,
            data,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer sk_test_51Mi3L3DmsES7FBrFICUed9W4PnS1bO30Y1tlD2gQ3oOvYYFqweYxABDpMnnupcbaDIwYlMOpzWbtQMqLyrFHvIfr00PTQPARZx`
            },
            proxy: {
                protocol: 'https',
                host: '127.0.0.1',
                // hostname: '127.0.0.1' // Takes precedence over 'host' if both are defined
                port: 9000
            },
        })
    }

}

export default Axios;
